enum AuthRoutes {
  ACCOUNT_SETTINGS_INDEX = '/server/account/settings',

  SAFETY_INDEX = '/safety',
  SAFETY_2FA = '/safety/2fa',
  SAFETY_DEVICES = '/safety/devices',
  SAFETY_IP_PROTECTION = '/safety/ip',
  CHANGE_EMAIL = '/safety/change-email',
  VERIFICATION_INDEX = '/verification',
  CONFIRM_EMAIL = '/api/confirm-email',
  MY_DEALS = '/deals',
  OPTIONS = '/options',
  DEAL_INFO = '/deals/:id',
  WALLET = '/coin-accounts',
  TRANSACTIONS = '/coin-accounts/:coin',
  SETTINGS_NOTIFICATION = '/notifications-settings',
  NOTIFICATIONS = '/notifications',
}

enum ErrorPages {
  NOT_FOUND = '/not-found',
}

enum FormsPages {
  LOGIN = '/login-page',
  RECOVER_PASSWORD = '/safety/change-password',
  REGISTER = '/register',
  REGISTER_CONFIRM = '/register-confirm',
  CONFIRM_INVITE = '/confirm-invite',
  PRIVACY_POLICY = '/policy',
  TERMS_OF_USE = '/terms',
}

const ROUTER_PATH = {
  AuthRoutes,
  ErrorPages,
  FormsPages,
};

export { ROUTER_PATH };
